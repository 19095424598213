@tailwind base;
@tailwind components;
@tailwind utilities;

.default-share {
    @apply flex absolute left-1/2 w-fit h-full max-h-fit p-8 mx-auto z-10 bottom-0;
    transform: translateX(-50%);
    top: -45%;
}
#share {
    @apply relative z-20;
}
.default-share.is-open {
    @apply flex;
}
.share-wrap {
    @apply flex gap-20 items-center;
}
.share-icon {@apply transition-all; }

.shareIcon.in path {
    stroke-dasharray: 300;
    stroke-dashoffset: 0;
    animation: slideup 2s linear(0, 0.5);
}
.shareIcon.out path {
    stroke-dasharray: 300;
    stroke-dashoffset: 300;
    animation: slidedown 2s linear(0, 0.5);
}
.shareIcon path { transform: translate(0px, 3px); }
.shareIcon #up { transition: transform .5s; }
.shareIcon.up #up {
  transform: translate(0px, -2px);
}

@keyframes slideup {
    from {
      stroke-dashoffset: 300;
    }
    to {
      stroke-dashoffset: 0;
    }
}
@keyframes slidedown {
    from {
      stroke-dashoffset: 300;
    }
    to {
      stroke-dashoffset: 0;
    }
}