@tailwind base;
@tailwind components;
@tailwind utilities;
/* @import './style/player.css';*/
@import './style/share.css';
@import './style/stars.css'; 
@import './style/sounds.css'; 

html {
  overscroll-behavior: none;
  /* prevent pull down to refresh. */
}

.App {
  @apply text-[#000] text-center bg-dark h-screen w-screen flex items-center justify-center flex-col overflow-hidden;
  /* max-height: -webkit-fill-available; */
}
.App > * {
  @apply text-light;
}
button { @apply cursor-pointer block; }
.btn {
  @apply w-fit mx-auto p-2 mt-8;
  background: rgba(36, 36, 36, 1);
  
}
nav {
  @apply bg-nav flex;
  transition: all .5s ease;
  border-bottom: 1px solid #333333;
}
nav svg {@apply text-lg;}

.collective {
  @apply left-0 right-0 h-full pb-32 overflow-scroll w-screen bg-dark-500 z-30 absolute backdrop-blur-md top-0 overflow-x-hidden;
  /* transition: all .5s ease; */
  /* transform: translateY(100px); */
}
#close-btn {
  @apply absolute z-40 left-4 top-0 mt-4;
}
.artist {
  @apply grid gap-4;
  grid-template-columns: .5fr 2fr;
}
.artist + .artist {@apply mt-6;}
@media all and (min-width: 1024px) {
  .artist {
    grid-template-columns: 1fr 2fr;
  }
}

.prev-block, .next-block {
  @apply flex flex-col justify-center absolute h-56 lg:h-72 w-full;
}

#transcript + .btn {
  width: 200px;
  position: absolute;
  left: 50%;
  margin-left: -100px;
  bottom: 3em;
  @media all and (min-width: 1024px) {
    bottom: 6em;
  }
}