.soundfield {
    @apply relative;
    perspective: 180vw;
}
.soundfield .star-fill {
    @apply absolute w-4 h-4 rounded-full mix-blend-color mt-2 ml-2 pointer-events-auto;
    background: radial-gradient(#ffffff66,#ffffff00);
}
.soundfield .star {
    @apply absolute w-8 h-8 cursor-pointer -mt-4 -ml-4 pointer-events-none;
    transition: transform .5s;
}
.soundfield .star .star-0, .soundfield .star .star-1, .soundfield .star .star-2 {
    @apply w-full h-full absolute;
    mask-size: contain;
    background-color: #fff;
}
.soundfield .star .star-1, .soundfield .star .star-2 {
    transform: scale(0);
    transition: transform .2s;
}
.soundfield .star:hover .star-1 {
    transform: scale(.7);
}
.soundfield .star .star-2.active {
    transform: scale(.7);
}
.soundfield .star label {
    /* @apply absolute text-xs; */
    @apply hidden;
}
.soundfield .star .fill-1 {
    mask-image: url(../assets/stars/stars_0017_Animated-Star-1.png);
}
.soundfield .star .star-1.fill-1 {
    mask-image: url(../assets/stars/stars_0016_Animated-Star-1a.png);
}
.soundfield .star .fill-1.star-2 {
    mask-image: url(../assets/stars/stars_0015_Animated-Star-1b.png);
}
.soundfield .star .fill-2 {
    mask-image: url(../assets/stars/stars_0014_Animated-Star-2.png);
}
.soundfield .star .star-1.fill-2 {
    mask-image: url(../assets/stars/stars_0013_Animated-Star-2a.png);
}
.soundfield .star .fill-2.star-2 {
    mask-image: url(../assets/stars/stars_0012_Animated-Star-2b.png);
}
.soundfield .star .fill-3, .soundfield .star .fill-7 {
    mask-image: url(../assets/stars/stars_0011_Animated-Star-3.png);
}
.soundfield .star .star-1.fill-3, .soundfield .star .star-1.fill-7 {
    mask-image: url(../assets/stars/stars_0010_Animated-Star-3a.png);
}
.soundfield .star .fill-3.star-2, .soundfield .star .fill-7.star-2 {
    mask-image: url(../assets/stars/stars_0009_Animated-Star-3b.png);
}
.soundfield .star .fill-4 {
    mask-image: url(../assets/stars/stars_0008_Animated-Star-4.png);
}
.soundfield .star .star-1.fill-4 {
    mask-image: url(../assets/stars/stars_0007_Animated-Star-4a.png);
}
.soundfield .star .fill-4.star-2 {
    mask-image: url(../assets/stars/stars_0006_Animated-Star-4b.png);
}
.soundfield .star .fill-5 {
    mask-image: url(../assets/stars/stars_0005_Animated-Star-5.png);
}
.soundfield .star .star-1.fill-5 {
    mask-image: url(../assets/stars/stars_0004_Animated-Star-5a.png);
}
.soundfield .star .fill-5.star-2 {
    mask-image: url(../assets/stars/stars_0003_Animated-Star-5b.png);
}
.soundfield .star .fill-6, .soundfield .star .fill-8 {
    mask-image: url(../assets/stars/stars_0002_Animated-Star-6.png);
}
.soundfield .star .star-1.fill-6, .soundfield .star .star-1.fill-8 {
    mask-image: url(../assets/stars/stars_0001_Animated-Star-6a.png);
}
.soundfield .star .fill-6.star-2,.soundfield .star .fill-8.star-2 {
    mask-image: url(../assets/stars/stars_0000_Animated-Star-6b.png);
}
.soundfield .star .star-0, .soundfield .star .star-2.active {
    animation: pulse 5s infinite alternate;
}
.soundfield .star.t4 .star-0, .soundfield .star.t4 .star-2.active {
    animation-duration: .7s;
}
.soundfield .star.t3 .star-0, .soundfield .star.t3 .star-2.active {
    animation-duration: 1.6s;
}
.soundfield .star.t2 .star-0, .soundfield .star.t2 .star-2.active {
    animation-duration: 2.9s;
}
.soundfield .star.t0 .star-0, .soundfield .star.t0 .star-2.active {
    animation-duration: 30s;
}
@keyframes pulse {
    from { transform: scale(1); }
    to { transform: scale(.5); }
}
