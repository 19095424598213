.starfield, .starfield img {
    @apply absolute block top-0 left-0 h-screen w-screen mix-blend-screen pointer-events-none;
}
.starfield {
    @apply overflow-hidden brightness-[.6];
}
.starfield img {
    @apply lg:object-cover;
}
.starfield .stars {
    @apply brightness-50 contrast-200;
}
/* .starfield.showing {
    @apply ;
} */

